import { useStore } from 'store';
import { useFetchInventoryHook } from 'hooks/inventory/useFetchInventoryHook';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { useFetchProductsHook } from 'hooks/products/useFetchProductsHook';
import { useFetchProductsCategoryHook } from 'hooks/products/useFetchProductsCategoryHook';
import { useFetchProductsGroupHook } from 'hooks/products/useFetchProductsGroupHook';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { BarChartComponent } from 'components/charts/barchart';
import { PiechartComponent } from 'components/charts/piechart';
import { LinechartComponent } from 'components/charts/linechart';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { useFetchInventoryReports } from 'hooks/inventory/useFetchInventoryReports';

export const InventoryOverviewPage = () => {
  const { branch } = useStore((state) => state);

  const { inventoryReportsData, inventoryReportsLoading } =
    useFetchInventoryReports('inventory-reports', branch?.id);
  console.log(
    '🚀 ~ InventoryOverviewPage ~ inventoryReportsData:',
    inventoryReportsData
  );

  return (
    <>
      <header className={styles.header}>
        <div>
          <div className={styles.headCard} style={{ background: `#40C978` }}>
            <div>
              <p>Total items</p>
              <h5>{inventoryReportsData?.totalItems || 0}</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`all-products`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.headCard} style={{ background: `#FE9F43` }}>
            <div>
              <p>Low stock</p>
              <h5>{inventoryReportsData?.lowStockCount || 0}</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`users`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.headCard} style={{ background: `#F06071` }}>
            <div>
              <p>Expired</p>
              <h5>{inventoryReportsData?.expiredCount || 0}</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`locationPin`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
        {/* <div>
          <div className={styles.headCard} style={{ background: `#7B3ACE` }}>
            <div>
              <p>Most popular</p>
              <h5>28</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`locationPin`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div> */}
        <div>
          <div className={styles.headCard} style={{ background: `#00CFE8` }}>
            <div>
              <p>Suppliers</p>
              <h5>{inventoryReportsData?.suppliersCount || 0}</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`locationPin`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
      </header>
      <div className={styles.content}>
        <div>
          <div className={styles.cardBx}>
            <header className={styles.cardHeader}>
              <div>
                <h6>Item inventory</h6>
                {/* <div className={styles.badge}>Buying</div> */}
              </div>
              <div>
                {/* <div className={styles.dateFilter}>Last date added</div> */}
              </div>
            </header>
            <div>
              <BarChartComponent
                chartData={
                  inventoryReportsData?.inventoriesByDate &&
                  Object.keys(inventoryReportsData?.inventoriesByDate).length >
                    0
                    ? Object.values(
                        inventoryReportsData?.inventoriesByDate
                      )?.flat(1)
                    : []
                }
                paddHeightInPercent={`46.6%`}
              />
            </div>
          </div>
          <div className={styles.fRow}>
            <div>
              <div className={styles.cardBx}>
                <header className={styles.cardHeader}>
                  <div>
                    <h6>Items by revenue</h6>
                  </div>
                  <div>
                    {/* <div className={styles.dateFilter}>This Week</div> */}
                  </div>
                </header>
                <div>
                  <PiechartComponent
                    chartData={
                      inventoryReportsData?.itemsByRevenue.length > 0
                        ? inventoryReportsData?.itemsByRevenue
                        : []
                    }
                  />
                </div>
              </div>
            </div>
            {/* <div>
              <div className={styles.primaryCard}>
                <div>
                  <div className={styles.primaryCardIcon}>
                    <Ikon name={`all-products`} fillColor={`var(--white)`} />
                  </div>
                </div>
                <div>
                  <div>
                    <p>All Products</p>
                    <h4>45</h4>
                  </div>
                  <div>
                    <p>In-stock</p>
                    <h4>
                      32 <small>+24%</small>
                    </h4>
                  </div>
                </div>
              </div>
              <div className={styles.cardBx} style={{ padding: `8px 12px` }}>
                <header className={styles.cardHeader}>
                  <div>
                    <h6>Inventory usage</h6>
                  </div>
                  <div>
                    <div className={styles.dateFilter}>Nov</div>
                  </div>
                </header>
                <div>
                  <LinechartComponent />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div>
          <div className={styles.cardBx}>
            <header className={styles.cardHeader}>
              <div>
                <h6>Item inventory</h6>
              </div>
              <div>
                <div className={styles.dateFilter}>Lastest 10 items</div>
              </div>
            </header>
            <div className={styles.inventoryList}>
              <div className={styles.listHeader}>
                <span>Product Name</span>
                <span>Quantity</span>
              </div>
              {inventoryReportsData?.latestInventories?.length > 0 ? (
                inventoryReportsData?.latestInventories
                  ?.slice(0, 10)
                  .map((item) => (
                    <div key={item?.id} className={styles.listItem}>
                      <span className={styles.productName}>
                        {item?.ProductModel?.product_name}
                      </span>
                      <span className={styles.quantity}>{item?.quantity}</span>
                    </div>
                  ))
              ) : (
                <div className={styles.noData}>
                  No inventory items available
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
