import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const DashboardLineChart = ({ data, keyName, itemsData }) => {
  const [noData, setNoData] = useState(data?.length > 0 ? false : true);

  return (
    <div className={styles.bx}>
      <h4>Top {itemsData?.length} Items</h4>
      <RenderIf isTrue={!noData}>
        {/* <ResponsiveContainer height={106}>
          <LineChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 20
            }}
          >
            <CartesianGrid
              stroke={`rgba(183, 184, 193, 1)`}
              horizontal={false}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={(value) =>
                new Intl.DateTimeFormat('en-US', {
                  month: 'short',
                  day: 'numeric'
                }).format(new Date(value))
              }
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={keyName}
              stroke="rgba(165, 97, 216, 1)"
              activeDot={{ r: 8 }}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer> */}
        <div className={styles.table}>
          <div className={`${styles.tr} ${styles.thead}`}>
            {/* <div>Name</div> */}
            {/* <div></div> */}
            {/* <div>Price</div> */}
          </div>
          {itemsData?.map((item, index) => (
            <div className={styles.tr}>
              <div>{item?.item}</div>
              {/* <div></div> */}
              {/* <div>${item?.price}</div> */}
            </div>
          ))}
        </div>
      </RenderIf>
      <RenderIf isTrue={noData}>
        <div
          style={{
            width: `100%`,
            height: 250,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            backgroundColor: `#D9D9D9`,
            color: `#707070`,
            fontSize: `14px`,
            fontWeight: `600`,
            borderRadius: `0 0 8px 8px`
          }}
        >
          No data
        </div>
      </RenderIf>
    </div>
  );
};
