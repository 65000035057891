import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const DashboardOrderType = ({ data }) => {
  return (
    <div className={styles.bx}>
      <h4>Orders by Type</h4>
      <div className={styles.table}>
        <div className={styles.tr}>
          <div>
            <Ikon name={`check-order-type`} />
            <div>Dine in</div>
          </div>
          <div>{data?.dineInOrders}</div>
        </div>
        <div className={styles.tr}>
          <div>
            <Ikon name={`check-order-type`} />
            <div>Take away</div>
          </div>
          <div>{data?.takeAwayOrders}</div>
        </div>
        <div className={styles.tr}>
          <div>
            <Ikon name={`check-order-type`} />
            <div>Delivery</div>
          </div>
          <div>{data?.deliveryOrders}</div>
        </div>
      </div>
    </div>
  );
};
