import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import styles from './index.module.css';

const data = [
  { name: 'Group A', value: 34249 },
  { name: 'Group B', value: 1420 }
];
const COLORS = ['rgba(165, 97, 216, 1)', 'rgba(228, 215, 243, 1)'];

export const DashboardPiechart = ({
  chartData,
  repeatedCustomer,
  newCustomers
}) => {
  return (
    <div className={styles.bx}>
      <h4>Customers Engagement</h4>
      <div style={{ width: `100%`, height: 156 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={chartData || data}
              innerRadius={55}
              outerRadius={76}
              paddingAngle={-2}
              dataKey="value"
              stroke="none"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legendRw}>
        <div>
          <h3>{newCustomers}</h3>
          <p>
            <span style={{ background: `rgba(228, 215, 243, 1)` }}></span>
            New Customers
          </p>
        </div>
        <div>
          <h3>{repeatedCustomer}</h3>
          <p>
            <span style={{ background: `var(--primary)` }}></span>
            Repeated
          </p>
        </div>
      </div>
    </div>
  );
};
