import React, { useState } from 'react';
import {
  AreaChart,
  Area,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  XAxis
} from 'recharts';
import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const DashboardAreaChartBig = ({ data, keyName }) => {
  const [noData, setNoData] = useState(data?.length > 0 ? false : true);

  return (
    <div className={styles.bx}>
      <h4>Sales</h4>
      <RenderIf isTrue={!noData}>
        <ResponsiveContainer height={259}>
          <AreaChart
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 20
            }}
          >
            <defs>
              <linearGradient id={'colorUv'} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#A561D8" stopOpacity={0.24} />
                <stop offset="95%" stopColor="#A561D8" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid
              stroke={`rgba(183, 184, 193, 1)`}
              horizontal={true}
              vertical={false}
            />
            <XAxis dataKey="date" />
            <YAxis
              tick={{ stroke: '#FFFFFF', strokeWidth: 0 }}
              tickFormatter={(value) =>
                new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short'
                }).format(value)
              }
            />
            <Tooltip />
            {/* <Legend /> */}
            <Area
              type="monotone"
              dataKey={keyName}
              stroke="rgba(165, 97, 216, 1)"
              strokeWidth={4}
              fill="url(#colorUv)"
              fillOpacity={1}
            />
          </AreaChart>
        </ResponsiveContainer>
      </RenderIf>
      <RenderIf isTrue={noData}>
        <div
          style={{
            width: `100%`,
            height: 250,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            backgroundColor: `#D9D9D9`,
            color: `#707070`,
            fontSize: `14px`,
            fontWeight: `600`,
            borderRadius: `0 0 8px 8px`
          }}
        >
          No data
        </div>
      </RenderIf>
    </div>
  );
};
