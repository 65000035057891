import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import { useStore } from 'store';
import { useFetchDevicesHook } from 'hooks/finix-payments/useFetchDevices';
import { RenderIf } from 'utilities/helpers';
import { PrimaryButton } from 'components/button';
import { DeviceCard } from 'components/cards/device';
import { Loading } from 'components/loading';

export const Devices = () => {
  const { brand, branch } = useStore((state) => state);
  const navigate = useNavigate();

  const { devicesData, devicesLoading } = useFetchDevicesHook(
    `devices/brandId/${branch?.id}`,
    branch?.id,
    'BRANCH'
  );

  const devices = devicesData?.result?.responsePaymentService?.result;
  console.log('devices', devices);

  return (
    <div className={styles.page}>
      <RenderIf isTrue={devicesLoading}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}
        >
          <Loading />
        </div>
      </RenderIf>

      <RenderIf isTrue={!devicesLoading}>
        <header className={styles.header}>
          <h1 style={{ fontSize: '24px', fontWeight: 'bold' }}>Devices</h1>
          <div>
            <PrimaryButton
              onClick={() => {
                navigate(`/devices/create`);
              }}
            >
              Add Device
            </PrimaryButton>
          </div>
        </header>
        <RenderIf isTrue={devices?.length === 0}>
          <div>No devices found</div>
        </RenderIf>
        <RenderIf isTrue={devices?.length > 0}>
          <div className={styles.listBx}>
            {devices?.map((device) => (
              <div className={styles.listItem} key={device?._id}>
                <DeviceCard
                  name={device?.name}
                  description={device?.description}
                  modalNumber={device?.model}
                  serialNumber={device?.serialNumber}
                  status={device?.enabled}
                  statusTxt={device?.enabled ? `Enabled` : `Disabled`}
                  onClick={() => {
                    navigate(`/devices/details/${device?.deviceId}`);
                  }}
                />
              </div>
            ))}
          </div>
        </RenderIf>
      </RenderIf>
    </div>
  );
};
