import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import styles from './index.module.css';

const data = [
  { name: 'Group A', value: 250 },
  { name: 'Group B', value: 120 },
  { name: 'Group C', value: 400 }
];
const data02 = [{ name: 'No value', value: 770 }];

const generateRandomColors = (count) => {
  return Array.from({ length: count }, () => {
    return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
      Math.random() * 256
    )}, ${Math.floor(Math.random() * 256)}, 1)`;
  });
};

const COLORS = generateRandomColors(30);

export const DashboardCategoryPiechart = ({ chartData }) => {
  return (
    <div className={styles.bx}>
      <h4>Top Categories</h4>
      <div className={styles.legendRw}>
        {(chartData || data).slice(0, 5).map((item, index) => (
          <div key={index} className={styles.legendItem}>
            <span
              className={styles.colorSquare}
              style={{
                background: `rgba(165, 97, 216, 1)`,
                width: '12px',
                height: '12px',
                display: 'inline-block',
                marginRight: '8px'
              }}
            />
            <p className={styles.categoryName}>
              {item?.categoryName || item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
