import { useQuery } from 'react-query';
import { AXIOS_INSTANCE_ODS } from 'utilities/db';

export const useFetchDevicesHook = (keyName, merchantId, type) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getData(merchantId, type),
    {
      enabled: !!merchantId && !!type
    }
  );

  return {
    devicesStatus: status,
    devicesData: data,
    devicesError: error,
    devicesFetching: isFetching,
    devicesLoading: isLoading,
    devicesRefetch: refetch
  };
};

const getData = async (merchantId, type) => {
  try {
    let { data } = await AXIOS_INSTANCE_ODS.get(
      `/device/getDevicesByMerchant/${merchantId}/${type}`
    );
    return data;
  } catch (error) {
    if (error?.response?.status === 400) {
      return {
        result: null,
        error: 'Devices not found'
      };
    }
    throw error;
  }
};
