import { PrimaryButton } from 'components/button';
import { Toasts } from 'components/toasts';
import { useFetchFinixMarkup } from 'hooks/finix-payments/useFetchFinixMarkup';
import { useState } from 'react';
import { Slider } from 'rsuite';
import { useStore } from 'store';
import { AXIOS_INSTANCE_ONBOARDING } from 'utilities/db';
import styles from './styles.module.css';

const BrandPaymentContracts = () => {
  const { branch } = useStore();
  const { finixMarkupData, finixMarkupLoading } = useFetchFinixMarkup(
    'finixMarkup',
    branch?.id
  );
  console.log(
    '🚀 ~ BrandPaymentContracts ~ finixMarkupData:',
    Number(finixMarkupData?.markup)
  );

  const [markup, setMarkup] = useState(Number(finixMarkupData?.markup) || 0);

  const handleSliderChange = (value) => {
    console.log('🚀 ~ handleSliderChange ~ value:', value);
    setMarkup(value);
  };

  const handleSave = async () => {
    try {
      await AXIOS_INSTANCE_ONBOARDING.post(`/branches/addPaymentLevel`, {
        branch_id: branch?.id,
        markup: Number(markup),
        payment_level: 'BRANCH'
      });
    } catch (error) {
      console.log('🚀 ~ handleSave ~ error:', error);
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Brand Payment Contracts</h1>
        <p className={styles.description}>
          Please select how much markup you will pay to a brand on every
          transaction.
        </p>
      </div>

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.sliderSection}>
            <div className={styles.sliderValue}>
              <span>Current Markup:</span>
              <span className={styles.value}>{markup}%</span>
            </div>
            <Slider
              value={markup}
              onChange={handleSliderChange}
              className={styles.slider}
            />
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton
              disabled={markup === 0}
              onClick={handleSave}
              className={styles.button}
            >
              {Number(finixMarkupData?.markup) === 0 ? 'Save' : 'Update'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPaymentContracts;
