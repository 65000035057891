import React from 'react';
import styles from './NoDataState.module.css';

export const NoDataState = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.illustration}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
        <h3 className={styles.title}>No Data Available</h3>
        <p className={styles.description}>There is no data to display.</p>
      </div>
    </div>
  );
};
