import { useQuery } from 'react-query';
import { AXIOS_INSTANCE_ODS } from 'utilities/db';

export const useFetchFinixPaymentHook = (keyName, externalId, level) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getData(externalId, level),
    {
      enabled: !!externalId && !!level
    }
  );

  return {
    paymentStatus: status,
    paymentData: data,
    paymentError: error,
    paymentFetching: isFetching,
    paymentLoading: isLoading,
    paymentRefetch: refetch
  };
};

const getData = async (externalId, level) => {
  try {
    let { data } = await AXIOS_INSTANCE_ODS.post(
      `/merchant/getAccountInformation`,
      {
        external_id: externalId,
        level
      }
    );
    return data;
  } catch (error) {
    console.log('🚀 ~ getData ~ error:', error?.response?.status);
    if (error?.response?.status === 400) {
      return {
        result: null,
        error: 'Payment account not found'
      };
    }
    throw error;
  }
};
