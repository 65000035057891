import { useEffect, useState } from 'react';
import { IconButton, Table } from 'rsuite';
import { useStore } from 'store';
import { useFetchOrdersHook } from 'hooks/orders/useFetchOrdersHook';
import { RenderIf } from 'utilities/helpers';
import { CustomTable } from 'components/datatable';
import styles from './index.module.css';
import moment from 'moment';
import CollaspedOutlineIcon from '@rsuite/icons/ArrowUpLine';
import ExpandOutlineIcon from '@rsuite/icons/ArrowDownLine';
import momentTZ from 'moment-timezone';
import { Loading } from 'components/loading';

export const OrdersPage = () => {
  const { Column, HeaderCell, Cell } = Table;
  const { brand, branch } = useStore((state) => state);
  const [fromDate, setFromDate] = useState(
    moment(new Date())?.format('YYYY-MM-DD')
  );
  const [todayDate, setTodayDate] = useState(
    moment(new Date())?.format('YYYY-MM-DD')
  );
  const [active, setActive] = useState(`TODAY`);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const { ordersData, ordersLoading, ordersRefetch } = useFetchOrdersHook(
    'orders',
    brand?.id,
    branch?.id,
    fromDate,
    todayDate
  );
  const Orders = ordersData?.data;

  const OrderDateCell = ({ rowData, dataKey, ...props }) => {
    return <Cell {...props}>{rowData?.orderDate}</Cell>;
  };

  const OrderStatusCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div
          style={{
            width: 70,
            height: 20,
            borderRadius: 3,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            fontSize: 11,
            color: `rgba(88, 99, 111, 1)`,
            fontWeight: `600`,
            background:
              rowData?.orderStatus === `In Progress`
                ? `#F4D9C6`
                : rowData?.orderStatus === `Cancelled`
                ? `#EFD4D4`
                : `rgba(221, 243, 215, 1)`
          }}
        >
          {rowData?.orderStatus === `PENDING`
            ? `In-progress`
            : rowData?.orderStatus === `CANCELLED`
            ? `Cancelled`
            : rowData?.orderStatus}
        </div>
      </Cell>
    );
  };

  const OrderTypeCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div
          style={{
            width: 70,
            height: 20,
            borderRadius: 3,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            fontSize: 11,
            color: `rgba(88, 99, 111, 1)`,
            fontWeight: `600`,
            background:
              rowData?.orderType === `TAKE AWAY`
                ? `#F3E3D7`
                : rowData?.orderType === `DELIVERY`
                ? `#DDF3D7`
                : `#E4D7F3`
          }}
        >
          {rowData?.orderType === `TAKE AWAY`
            ? `Take Away`
            : rowData?.orderType === `DELIVERY`
            ? `Delivery`
            : `Dine in`}
        </div>
      </Cell>
    );
  };

  const OrderViaCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <div
          style={{
            fontSize: 12,
            fontWeight: `500`
          }}
        >
          {rowData?.orderVia === `ZEWST_APP`
            ? `App`
            : rowData?.orderVia === `ZEWST_ONLINE`
            ? `Online store`
            : `-`}
        </div>
      </Cell>
    );
  };

  useEffect(() => {
    ordersRefetch();
  }, [fromDate, todayDate]);

  useEffect(() => {
    handleButtonClick(`MONTH`, 30);
  }, []);

  const handleButtonClick = (value, num) => {
    const d = new Date();
    setTodayDate(moment(d)?.format('YYYY-MM-DD'));
    setActive(value);
    const priorDate = new Date(new Date().setDate(d.getDate() - num));
    const formatPriorDate = moment(priorDate)?.format('YYYY-MM-DD');
    setFromDate(formatPriorDate);
  };

  const ExpandCell = ({
    rowData,
    dataKey,
    expandedRowKeys,
    onChange,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
            <CollaspedOutlineIcon />
          ) : (
            <ExpandOutlineIcon />
          )
        }
      />
    </Cell>
  );

  const renderRowExpanded = (rowData) => {
    console.log('🚀 ~ renderRowExpanded ~ rowData:', rowData);
    return (
      <div className={styles.rowExpandBx}>
        <div className={styles.expandItemBx}>
          <h6>Items</h6>
          {rowData?.itemDetails?.map((item, i) => {
            return (
              <div className={styles.item} key={i}>
                <div>
                  <img src={item?.image} alt={item?.name} />
                  <h6>
                    {
                      rowData?.items?.find((i) => item?._id === i?.itemId)
                        ?.quantity
                    }
                    x {item?.item}
                  </h6>
                </div>
                <h6>
                  {item?.price *
                    rowData?.items?.find((i) => item?._id === i?.itemId)
                      ?.quantity}
                </h6>
                {/* <div>
                  <div>
                    Quantity:{' '}
                    {rowData?.items?.find((i) => item?._id === i?.itemId)?.quantity}
                  </div>
                  <div>
                    {item?.price} x{' '}
                    {
                      rowData?.items?.find((i) => item?._id === i?.itemId)
                        ?.quantity
                    }{' '}
                    ={' '}
                    {item?.price *
                      rowData?.items?.find((i) => item?._id === i?.itemId)
                        ?.quantity}
                  </div>
                </div> */}
              </div>
            );
          })}
        </div>
        <div className={styles.expandItemBx}>
          <h6>Customer Details</h6>
          <div className={styles.item}>
            <p>Name:</p>
            <h6>{rowData?.customer?.fullName}</h6>
          </div>
          <div className={styles.item}>
            <p>Phone number:</p>
            <h6>{rowData?.customer?.contact?.[0]?.contactNumber}</h6>
          </div>
          <div className={styles.item}>
            <p>Email:</p>
            <h6>{rowData?.customer?.email}</h6>
          </div>
        </div>
        <div className={styles.expandItemBx}>
          <h6>Price Calculator</h6>
          <div className={styles.item}>
            <p>Subtotal:</p>
            <h6>{rowData?.subTotal}</h6>
          </div>
          <div className={styles.item}>
            <p>Tax:</p>
            <h6>{rowData?.tax}</h6>
          </div>
          <div className={styles.item}>
            <p>Delivery fee:</p>
            <h6>{rowData?.deliveryFee}</h6>
          </div>
          <div className={styles.item}>
            <p>Discount:</p>
            <h6>{rowData?.discount}</h6>
          </div>
          <hr />
          <div className={styles.item}>
            <h6>Total amount:</h6>
            <h6>{rowData?.total}</h6>
          </div>
        </div>
      </div>
    );
  };

  const rowKey = 'id';

  return (
    <>
      <article className={styles.article}>
        <header className={styles.header}>
          <div>
            <h2>Orders History</h2>
            <h6>See all the details about your orders</h6>
          </div>
          <div className={styles.filterBx}>
            <button
              type={`button`}
              className={active === `TODAY` ? styles.active : ``}
              onClick={() => {
                handleButtonClick(`TODAY`, 0);
              }}
            >
              Today
            </button>
            <button
              type={`button`}
              className={active === `YESTERDAY` ? styles.active : ``}
              onClick={() => {
                handleButtonClick(`YESTERDAY`, 2);
              }}
            >
              Yesterday
            </button>
            <button
              type={`button`}
              className={active === `WEEK` ? styles.active : ``}
              onClick={() => {
                handleButtonClick(`WEEK`, 7);
              }}
            >
              Week
            </button>
            <button
              type={`button`}
              className={active === `MONTH` ? styles.active : ``}
              onClick={() => {
                handleButtonClick(`MONTH`, 30);
              }}
            >
              Month
            </button>
          </div>
        </header>
        <div className={styles.tableBx}>
          <RenderIf isTrue={ordersLoading}>
            <div
              className={styles.loading}
              style={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                height: `100%`
              }}
            >
              <Loading />
            </div>
          </RenderIf>

          <RenderIf isTrue={!ordersLoading && Orders?.length > 0}>
            <CustomTable
              rowHeight={52}
              rows={Orders?.filter((order) => order != null)
                ?.sort(
                  (a, b) => new Date(b?.orderedOn) - new Date(a?.orderedOn)
                )
                ?.map((order, index) => {
                  return {
                    id: index + 1,
                    customerEmail: order?.customer?.guest
                      ? 'Guest'
                      : order?.customer?.fullName?.replace(':', ' '),
                    orderTotal: order?.total?.toFixed(2),
                    orderId: order?.orderCode,
                    orderVia: order?.orderVia,
                    orderType: order?.type?.replace('_', ' '),
                    orderTime: momentTZ(order?.orderedOn)
                      .tz(momentTZ.tz.guess())
                      .format('hh:mm:ss A'),
                    orderDate: moment(order?.orderedOn)?.format('DD-MM-YYYY'),
                    orderStatus:
                      order?.payment === `PENDING`
                        ? `In Progress`
                        : order?.payment === `COMPLETED`
                        ? `Completed`
                        : `Cancelled`,
                    items: order?.items,
                    ...order
                  };
                })}
              loading={ordersLoading}
              pageLimit={10}
              pagination={Orders?.length > 10 ? true : false}
              renderRowExpanded={renderRowExpanded}
              expandedRowKeys={expandedRowKeys}
            >
              <Column width={40} align="center">
                <HeaderCell></HeaderCell>
                <ExpandCell
                  dataKey="id"
                  expandedRowKeys={expandedRowKeys}
                  onChange={handleExpanded}
                />
              </Column>
              <Column width={120}>
                <HeaderCell>Order Id</HeaderCell>
                <Cell dataKey="orderId" />
              </Column>
              <Column width={110} sortable>
                <HeaderCell>Order Total</HeaderCell>
                <Cell dataKey="orderTotal" />
              </Column>
              <Column flexGrow={1} sortable>
                <HeaderCell>Customer</HeaderCell>
                <Cell dataKey="customerEmail" />
              </Column>
              <Column width={120} sortable>
                <HeaderCell>Plateform</HeaderCell>
                <OrderViaCell dataKey="orderVia" />
              </Column>
              <Column width={90} sortable>
                <HeaderCell>Type</HeaderCell>
                <OrderTypeCell dataKey="orderType" />
              </Column>
              <Column width={130} sortable>
                <HeaderCell>Time</HeaderCell>
                <Cell dataKey="orderTime" />
              </Column>
              <Column width={130} sortable>
                <HeaderCell>Date</HeaderCell>
                <OrderDateCell dataKey="orderDate" />
              </Column>
              <Column width={90} sortable>
                <HeaderCell>Status</HeaderCell>
                <OrderStatusCell dataKey="orderStatus" />
              </Column>
              {/* <Column width={80} fixed="right">
                            <HeaderCell>Options</HeaderCell>
                            <Cell style={{ padding: '6px' }}>
                                {rowData => (
                                    <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                                    ...
                                    </Button>
                                )}
                            </Cell>
                        </Column> */}
            </CustomTable>
          </RenderIf>
          <RenderIf isTrue={!ordersLoading && Orders?.length === 0}>
            <div className={styles.noData}>No Orders data found</div>
          </RenderIf>
        </div>
      </article>
    </>
  );
};
