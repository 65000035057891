import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

// const data = [
//   { name: 'Group A', value: 120 },
//   { name: 'Group B', value: 200 },
//   { name: 'Group C', value: 450 },
//   { name: 'Group D', value: 180 },
//   { name: 'Group E', value: 185 },
//   { name: 'Group F', value: 180 },
//   { name: 'Group G', value: 170 }
// ];
const COLORS = [
  '#90CD3F',
  '#C13B8B',
  '#6A2DAB',
  '#3C4BB4',
  '#2C6FBC',
  '#4CACCD',
  '#52B234'
];

export const PiechartComponent = ({ chartData }) => {
  // console.log('🚀 ~ PiechartComponent ~ chartData:', chartData);
  const hasData = chartData && chartData.length > 0;

  return (
    <div style={{ width: `100%`, height: 256 }}>
      {hasData ? (
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={
                chartData?.map((item) => ({
                  name: item?.ProductModel?.product_name,
                  value: item?.revenuePercentage
                })) || []
              }
              cx="50%"
              cy="50%"
              outerRadius={80}
              label
            >
              {chartData?.map((entry, index) => (
                <Cell
                  // key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};
