import { useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { useFetchProductsHook } from 'hooks/products/useFetchProductsHook';
import { useFetchProductsCategoryHook } from 'hooks/products/useFetchProductsCategoryHook';
import { useFetchProductsGroupHook } from 'hooks/products/useFetchProductsGroupHook';
import { useFetchInventoryHook } from 'hooks/inventory/useFetchInventoryHook';
import { CustomSelectDropdown } from 'components/select/multi';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';
import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';

export const ManualInventoryPage = () => {
  const { brand, branch } = useStore((state) => state);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      vendor: '',
      product: '',
      category: '',
      group: '',
      quantity: '',
      unit: '',
      date_of_purchase: '',
      date_of_expiry: '',
      // price_per_unit: '',
      total_price: '',
      notes: '',
      brand: '',
      branch: '',
      low_stock_alert: ''
    }
  });

  const { productsData, productsLoading } = useFetchProductsHook(
    `products/${brand?.id}/${branch?.id}`,
    brand?.id,
    branch?.id
  );

  const { inventoryRefetch } = useFetchInventoryHook(
    `inventory/${brand?.id}`,
    branch?.id
  );

  const { mutate, isError, error, isSuccess } = useMutation((invData) => {
    return client.post('/inventory/add', invData);
  });

  const handleAddManualInventory = (data) => {
    const {
      product,
      quantity,
      low_stock_alert,
      unit,
      date_of_purchase,
      date_of_expiry,
      total_price,
      notes
    } = data;

    mutate({
      branch_id: +branch?.id,
      product_id: product?.value,
      quantity: Number(quantity),
      low_stock_alert: low_stock_alert || 0,
      unit: unit?.value,
      total_price: Number(total_price),
      purchase_date: date_of_purchase,
      expiry_date: date_of_expiry,
      notes: notes || ''
    });
  };

  useMemo(() => {
    if (isSuccess) {
      Toasts.success('Inventory added successfully');
      inventoryRefetch();
      navigate(`/inventory/listing`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(
      error?.response?.data?.message?.[1]?.msg || error?.response?.data?.message
    );
  }, [isError, error]);

  return (
    <>
      <form onSubmit={handleSubmit(handleAddManualInventory)}>
        <div className={styles.bx}>
          <div className={styles.row}>
            <div>
              <Controller
                name="product"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    field={field}
                    label="Product *"
                    placeholder="Choose options"
                    options={productsData?.map((prod) => ({
                      value: prod?.id,
                      label: prod?.product_name || '-'
                    }))}
                    error={
                      errors?.product?.type === 'required'
                        ? 'Product is required'
                        : ''
                    }
                    isLoading={productsLoading}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <TextField
                label="Quantity *"
                placeholder="Quantity"
                name="quantity"
                error={
                  errors?.quantity?.type === 'required'
                    ? 'Quantity is required'
                    : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />
            </div>
            <div>
              <TextField
                label="Low stock alert *"
                placeholder="Low stock alert*"
                name="low_stock_alert"
                error={
                  errors?.low_stock_alert?.type === 'required'
                    ? 'Low stock alert is required'
                    : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <Controller
                name="unit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    field={field}
                    label="Unit *"
                    placeholder="Choose options"
                    options={[
                      'kg',
                      'pounds',
                      'grams',
                      'ounces',
                      'liters',
                      'teaspoon',
                      'number/pieces',
                      'tablespoon',
                      'dozen'
                    ]?.map((unit) => ({
                      value: unit,
                      label: unit?.toUpperCase() || '-'
                    }))}
                    error={
                      errors?.unit?.type === 'required'
                        ? 'Unit is required'
                        : ''
                    }
                    //isLoading={brandsLoading}
                  />
                )}
              />
            </div>
            <div>
              <TextField
                label="Date of purchase *"
                placeholder="dd/mm/yyyy"
                name="date_of_purchase"
                error={
                  errors?.unit?.type === 'required'
                    ? 'Date of purchase is required'
                    : ''
                }
                register={register}
                validation={{
                  required: true
                }}
                type="date"
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <TextField
                label="Date of expiry *"
                placeholder="dd/mm/yyyy"
                name="date_of_expiry"
                error={
                  errors?.date_of_expiry?.type === 'required'
                    ? 'Date of expiry is required'
                    : ''
                }
                register={register}
                validation={{
                  required: true
                }}
                type="date"
              />
            </div>
            <div>
              <TextField
                label="Total Price *"
                placeholder="Total Price"
                name="total_price"
                error={
                  errors?.total_price?.type === 'required'
                    ? 'Total price is required'
                    : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div>
              <TextField
                label="Notes (optional)"
                placeholder="Notes"
                name="notes"
                error={
                  errors?.notes?.type === 'required' ? 'Notes is required' : ''
                }
                register={register}
                validation={{}}
              />
            </div>
            {/* <div>
              <Controller
                name="branch"
                control={control}
                // rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    field={field}
                    label="Branch (optional Select if inventory is at branch level else leave blank)"
                    placeholder="Choose options"
                    options={[
                      {
                        value: branch?.id,
                        label:
                          branch?.branch_name || branch?.kitchen_name || '-'
                      }
                    ]}
                    error={
                      errors?.branch?.type === 'required'
                        ? 'Branch is required'
                        : ''
                    }
                    //isLoading={branchesLoading}
                  />
                )}
              />
            </div> */}
          </div>
          {/* <div className={styles.row}>
            <div>
              <Controller
                name="brand"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    field={field}
                    label="Brand *"
                    placeholder="Choose options"
                    options={[
                      {
                        value: brand?.id,
                        label: brand?.brand_name || '-'
                      }
                    ]}
                    error={
                      errors?.brand?.type === 'required'
                        ? 'Brand is required'
                        : ''
                    }
                    //isLoading={brandsLoading}
                  />
                )}
              />
            </div>
            <div></div>
          </div> */}
          <div className={styles.footer}>
            <Button
              onClick={() => {
                navigate(`/inventory/listing`);
              }}
              color="outline-lite"
              label={'Cancel'}
            />
            <Button label="Submit" color="bg-primary" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
};
