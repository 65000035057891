import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchInventoryReports = (keyName = 'inventoryReports', id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [`${keyName}-${id}`, id],
    () => getInventoryReportsData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    inventoryReportsStatus: status,
    inventoryReportsData: data,
    inventoryReportsError: error,
    inventoryReportsFetching: isFetching,
    inventoryReportsLoading: isLoading,
    inventoryReportsRefetch: refetch
  };
};

const getInventoryReportsData = async (id) => {
  let result = await client.get(`inventory/findReportByBranch/${id}`);
  result = result.data;
  return result;
};
