import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { ModalComponent } from 'components/modal';
import { CustomSelectDropdown } from 'components/select/multi';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';

export const ProductCategoryPage = ({
  action,
  setAction,
  setAdditionType,
  refetchGroups,
  refetchCategories
}) => {
  const { brand, branch } = useStore((state) => state);
  console.log('🚀 ~ brand:', brand, branch);

  const [level, setLevel] = useState('branch');

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control
  } = useForm({
    defaultValues: {
      name: action === 'update' ? '' : '',
      brand: action === 'update' ? '' : '',
      branches: action === 'update' ? '' : ''
    }
  });

  useMemo(() => {
    if (action) {
      reset({
        name: action === 'update' ? '' : '',
        brand: action === 'update' ? '' : '',
        branches: action === 'update' ? '' : ''
      });
    } else {
      reset({
        name: '',
        brand: '',
        branches: ''
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (categoryData) => {
      if (action === 'update') {
        return client.post(`/productCategories/findOne/${1}`, categoryData);
      }
      return client.post('/productCategories/add', categoryData);
    }
  );

  const handleSubmitData = (data) => {
    const { name } = data;
    if (level === 'brand') {
      mutate({
        category_name: name,
        branch_id: null,
        brand_id: parseInt(brand?.id)
      });
    } else {
      mutate({
        category_name: name,
        branch_id: parseInt(branch?.id),
        brand_id: parseInt(brand?.id)
      });
    }
  };

  const closeModal = () => {
    setAction('');
    setAdditionType('');
  };

  useMemo(() => {
    if (isSuccess) {
      refetchGroups();
      refetchCategories();
      Toasts.success('Category added successfully');
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(
      error?.response?.data?.message?.[1]?.msg || error?.response?.data?.message
    );
  }, [isError]);

  return (
    <>
      <ModalComponent
        modal={closeModal}
        handleModal={closeModal}
        modalMiddle={false}
        rightModal={true}
      >
        <h4>
          <span className="ff_semi">
            {action === 'update' ? 'Update' : 'Add New'} Product Category
          </span>
        </h4>
        <div className="mt_40"></div>
        <div className="pt_10">
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
              label="Name"
              placeholder="Name"
              name="name"
              error={
                errors?.name?.type === 'required' ? 'Name is required' : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            />
            {/* <div className="mt_16"></div>
            <Controller
              name="brand"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  isMulti={false}
                  field={field}
                  label="Brand"
                  placeholder="Choose options"
                  options={[
                    {
                      value: brand?.id,
                      label: brand?.brand_name || '-'
                    }
                  ]}
                  error={
                    errors?.brand?.type === 'required'
                      ? 'Brand is required'
                      : ''
                  }
                  required={true}
                />
              )}
            />
            <div className="mt_30"></div>
            <Button
              label="Add Branch level"
              //color={level === 'branch' ? 'bg-primary' : 'outline-lite'}
              onClick={() => setLevel(level === 'branch' ? 'brand' : 'branch')}
              color="bg-primary"
            />
            <div className="mt_16"></div>
            {level === 'branch' && (
              <Controller
                name="branches"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    isMulti={true}
                    field={field}
                    label="Branch"
                    placeholder="Choose options"
                    options={[
                      {
                        value: branch?.id,
                        label:
                          branch?.branch_name || branch?.kitchen_name || '-'
                      }
                    ]}
                    error={
                      errors?.branches?.type === 'required'
                        ? 'Branch is required'
                        : ''
                    }
                    required={true}
                  />
                )}
              />
            )}
            <div className="mt_30"></div> */}
            {/* <hr className="hr_modal_right" /> */}
            <div className="flex item_hend gap_16 mt_30">
              <Button
                onClick={closeModal}
                color="outline-lite"
                label={'Cancel'}
              />
              <Button
                label={action === 'update' ? 'Update' : 'Submit'}
                color="bg-primary"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </ModalComponent>
    </>
  );
};
